import { getPriceChart, shopInfo } from "../core/shopdata"
import { h2Unlinked } from "../helpers/blockmaker"
export function System(){

    return(
        <main id="system">
            {h2Unlinked("料金")}
            <article className="wrapper-table">
/*
                // <img src={getPriceChart("2024-08-07T00:00:00")} alt={shopInfo.name+"料金表"} />
*/
            </article>
        </main>
    )
}

export default System